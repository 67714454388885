function AdminFilters () {
  $('#admin_role_selector').multiselect({
    enableFiltering: true,
    enableCaseInsensitiveFiltering: true,
    includeSelectAllOption: true,
    maxHeight: 250,
    buttonWidth: '100%'
  })
}

export default AdminFilters
